.Img {
  max-width: 95%;
}
.Container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  background: rgba(184, 182, 184, 0.5);
  height: 100%;
  width: 100%;
}
/* @media screen and (min-width: 1023px) {
  .Container {
    max-height: 400px;
  }
} */
@media screen and (min-width: 1440px) {
  .Img {
    max-width: 80%;
  }
}
